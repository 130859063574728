import React from 'react';
import { GridContainer, GridItem, H2 } from '@tuunetech/tuune-components';
import { InformedConsentContainer } from './styles';
import { DATA } from './data';
import { Paragraphs } from '../components';

export const InformedConsentLayout: React.FC = () => {
  return (
    <InformedConsentContainer>
      <GridContainer justifyContent="center">
        <GridItem lg={8}>
          <H2 align="center" $marginBottom={40}>
            INFORMED CONSENT TO TELEMEDICINE SERVICES
          </H2>
          <Paragraphs paragraphs={DATA.paragraphs} />
        </GridItem>
      </GridContainer>
    </InformedConsentContainer>
  );
};
