import React from 'react';
import { PageProps } from 'gatsby';
import { Layout } from 'components/shared';
import { InformedConsentLayout } from 'components/Policy/InformedConsentLayout';

const InformedConsent: React.FC<PageProps> = ({ location }) => {
  return (
    <Layout path={location.pathname}>
      <InformedConsentLayout />
    </Layout>
  );
};

export default InformedConsent;
