import styled from 'styled-components';
import { Container } from '@tuunetech/tuune-components';

export const InformedConsentContainer = styled(Container)`
  margin: 126px auto 86px;

  ul,
  ol {
    padding-left: 16px;
  }

  li {
    margin-top: 16px;
  }

  b {
    color: ${({ theme }) => theme.palette.text.primary};
  }
`;
