import React from 'react';
import { Par } from '../types';

export const DATA: { paragraphs: Par[] } = {
  paragraphs: [
    <>
      Uniq Health Inc., through its Tuune website and app (
      <b>
        <u>“Tuune”</u>
      </b>
      ), provides users the ability to complete a health assessment that is
      reviewed by a licensed physician (
      <b>
        <u>“Tuune Physician”</u>
      </b>
      ) to determine which contraceptives are clinically appropriate for the
      user (the{' '}
      <b>
        <u>“Health Assessment”</u>
      </b>
      ). These medical consultations do not occur in person or by live video.
      Users do not have direct communication with their assigned physician,
      although users may send messages through the Tuune website.
    </>,
    <>
      The purpose of this form is to explain some of the risks of telemedicine
      and obtain your consent to receiving medical services through the type of
      telemedicine described in this Consent.
    </>,
    <>
      <ol>
        <li>
          I understand that I will not be physically in the same room as my
          health care provider, and that I will not have real-time interaction
          with my health care provider.
        </li>
        <li>
          I understand that the same standard of care applies to telemedicine
          services as applies to in-person services.
        </li>
        <li>
          I understand that there are potential risks to using electronic
          communications technologies, including service interruptions,
          interception of data, corruption or loss of data, and technical
          difficulties.
        </li>
        <li>
          I understand that it may be more difficult for my Tuune Physician to
          diagnose me without seeing me in person. For example, it may be more
          difficult to perform a physical assessment, to check vitals, and to
          perform diagnostic tests.
        </li>
        <li>
          I understand that my Tuune Physician will only treat specific
          conditions, so I will need to access treatment for other medical
          conditions using a provider outside of the Tuune website.
        </li>
        <li>
          I understand that my Tuune Physician does not have access to medical
          records prepared by my other healthcare providers. As a result, my
          Tuune Physician does not have access to all of my medical information,
          just the information in my Health Assessment. I understand that if the
          information in my Health Assessment is not accurate or complete, that
          this could lead to adverse interactions with other medications that I
          might be taking, among other risks.
        </li>
        <li>
          I understand that I have the right to refuse to participate or decide
          to stop receiving telemedicine services at any time by deactivating my
          account with Tuune.
        </li>
        <li>
          I understand that the laws that protect privacy and the
          confidentiality of health care information apply to telemedicine
          services.
        </li>
        <li>
          I understand that this form will become a part of my medical record.
        </li>
      </ol>
    </>,
    <>
      <b>
        By checking this box, I attest that: (1) I have personally read this
        form and fully understand and agree to its contents and (2) I can
        confirm I am located in the state I signed up to Tuune with and will be
        located in said state while receiving telemedicine services arranged
        through Tuune.
      </b>
    </>,
  ],
};
